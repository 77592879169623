import React, { useState, useEffect } from 'react';
import './App.css';
import { selectRandom } from './random';
import olof from './olof.png';
import Fade from 'react-reveal/Flip';

function App() {
  const [valgtKommune, setValgtKommune] = useState('')
  const [show, setShow] = useState(false)

  const select = () => {
    const kommune = selectRandom();
    setValgtKommune(kommune);
    setShow(!show);
  };

  useEffect(() => () => setTimeout(() => setShow(true), 200), [show]);

  return (
    <div className="App">
      <header className="App-header">
        <div id="button1" onClick={select}>HVOR SKAL JEG NO ?</div>
      </header>
      {valgtKommune && <h3>Du skal til:</h3> }
      <Fade left opposite when={show}>
        <h1>{valgtKommune}</h1>
      </Fade>
      <img className='olof' alt='olof_palme' src={olof}></img>
    </div>
  );
}

export default App;
